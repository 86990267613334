// NavigationBar.jsx

// Copyright HS Analysis GmbH, 2020
// Author: Sebastian Murgul, Viktor Eberhardt

// Framework imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// External npm packages
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import {
  ArrowDropDown,
  Person,
  ExitToApp,
  CoPresent,
  Build,
  Info,
  History,
} from "@mui/icons-material";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import WorkIcon from "@mui/icons-material/Work";
import BarChartIcon from "@mui/icons-material/BarChart";
import ScannerIcon from "@mui/icons-material/Scanner";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import withStyles from "@mui/styles/withStyles";

// HSA Packages
import Backend from "../utils/Backend";
import { authenticationService } from "../services";
import { viewerType } from "../utils/Utils";

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    height: 50,
    cursor: "pointer",
  },
  appbarPlaceholder: theme.mixins.toolbar,
  headerButton: {
    textTransform: "none",
    height: 64,
    padding: "0 20px",
    borderRadius: 0,
  },
  menu: {
    transformOrigin: "center top",
    marginTop: 7,
    marginLeft: -40,
    minWidth: 200,
  },
  menuIcon: {
    marginRight: 10,
    color: "#444",
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
});

class NavigationBar extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      currentUser: null,
      open: false,
      headline: "",
      showDevButtons: false,
      resultsPath: "",
      isResultsButtonVisible: false,
      extraButtonState: "", //none, results, project
      scannerState: null,
      expirationMessage: "",
      canAccessLicensingPage: false,
      canAccessAdminPage: false,
      canAccessAITab: false,
      canAccessCases: false,
      canAccessScan: false,
      canAccessFileView: true,
    };

    window.setNavigationbarTitle = this.setNavigationbarTitle;
  }

  // check if report button should be shown
  // and check if report exist
  checkProjectButton = (pathname) => {
    let isResultsButtonVisible =
      pathname.includes("/view/") || pathname.includes("/esr_view/");
    if (this.state.isResultsButtonVisible !== isResultsButtonVisible) {
      this.setMountedState({ isResultsButtonVisible });
    }
    if (isResultsButtonVisible) {
      let uuid = pathname.split("view/").slice(-1)[0];
      let dir_path = "/" + uuid;
      Backend.walkProjectDirFiles(dir_path, (result) => {
        let reportExists = false;
        for (let row of result) {
          if (
            row.path.endsWith("report.xlsx") ||
            row.path.endsWith("report.xlsm")
          ) {
            reportExists = true;
            break;
          }
        }
        this.setMountedState({
          resultsPath: reportExists ? "/report/" + uuid : "",
          extraButtonState: "results",
        });
      });
    } else {
      if (pathname.includes("/report/")) {
        const uuid = pathname.split("report/").slice(-1)[0];
        Backend.loadProject(uuid)
          .then((project) => {
            this.setMountedState({
              extraButtonState: "project",
              projectPath: viewerType(project.type) + uuid,
            });
          })
          .catch(() => {
            console.info(
              "Project not found, probably a merged results project!"
            );
          });
      } else {
        this.setMountedState({
          extraButtonState: "",
          projectPath: "",
        });
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.location.pathname === "/iframe.html") return;

    //on first load
    this.checkProjectButton(location.pathname);
    this.setPageHeadline();

    //on path change
    this.props.history.listen((location) => {
      this.setPageHeadline();
      this.checkProjectButton(location.pathname);
    });

    authenticationService.currentUser.subscribe((x) => {
      if (!x) return;
      this.setMountedState({
        currentUser: x,
      });
      this.updateUserGroups();
    });

    Backend.getLicensingInfo((license) => {
      if (license.licenseStatus === "VALID") {
        this.setMountedState({
          editableProject: null,
          open: false,
        });
      } else {
        authenticationService.logout();
        this.props.history.push("/licensing");
      }
      let scannerState = "INVALID";
      let expirationMessage = "";
      if (license.activeModules.length === 0) {
        scannerState = "VALID";
      } else {
        const scannerData = license.activeModules.find(
          (item) => item.name === "HSAScanner"
        );
        if (!scannerData) return;

        const expirationDays = scannerData.expiresInDays;
        if (expirationDays < 31) {
          if (expirationDays < 0) {
            expirationMessage =
              expirationDays === -1
                ? "Expired for 1 day!"
                : "Expired for " + expirationDays + " days!";
          } else {
            expirationMessage =
              expirationDays === -1
                ? "Expires in 1 day!"
                : "Expires in " + expirationDays + " days!";
          }
        }
        scannerState = scannerData.isExpired ? "EXPIRED" : "VALID";
      }

      this.setMountedState({ scannerState, expirationMessage });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  updateUserGroups = () => {
    Backend.getCurrentUser((user) => {
      if (!user.group) return;

      this.setState({
        canAccessLicensingPage: user.group.canAccessLicensingPage,
        canAccessAdminPage: user.group.canAccessAdminPage,
        canAccessAITab: user.group.canAccessAITab,
        canAccessCases: user.group.canAccessCases,
        canAccessScan: user.group.canAccessScanner,
      });
    });
  };

  setNavigationbarTitle = (title) => {
    this.setMountedState({ headline: title });
  };

  logout = () => {
    authenticationService.logout();
    this.props.history.push("/login");
  };

  handleMenu() {
    this.setMountedState((state) => ({ open: !state.open }));
  }

  handleClose(event) {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setMountedState({ open: false });
  }

  setPageHeadline() {
    if (this.props.location.pathname === "/iframe.html") return;
    let path = window.location.href;
    const titleMap = {
      "/view/": "Viewer",
      "/report/": "Results",
      "/admin": "Admin Settings",
      "/login": "Login",
      "/about": "About",
      "/cases": "Cases",
      "/file_view": "Files",
      "/ai_view": "AI",
      "/classroom": "Classroom",
      "/prediction": "Prediction",
      "/licensing": "Licenseing",
      "/scan_view": "Scan Viewer",
    };
    Backend.getVersion((data) => {
      let title = "Projects";
      for (const pathFragment in titleMap) {
        if (path.includes(pathFragment)) {
          title = titleMap[pathFragment];
          break;
        }
      }

      const fullVersion = data.version.split(".");
      const versionLength = fullVersion.length;
      const version =
        versionLength > 3 ? fullVersion.slice(0, 3).join(".") : data.version;
      window.document.title = `${title} - HSA Kit ${version}`;
    });
    let headline = "Home";
    for (const pathFragment in titleMap) {
      if (path.includes(pathFragment)) {
        headline = titleMap[pathFragment];
        break;
      }
    }
    if (this.state.headline !== headline) {
      this.setMountedState({ headline: headline });
    }
    return headline;
  }

  render() {
    const {
      currentUser,
      open,
      showDevButtons,
      scannerState,
      expirationMessage,
      canAccessLicensingPage,
      canAccessAdminPage,
    } = this.state;
    const { classes } = this.props;

    return (
      <AppBar position="fixed">
        <Toolbar>
          <img
            className={classes.menuButton}
            onClick={() => this.props.history.push("/")}
            alt="hsa logo"
            src="/img/hsa_logo.png"
          />
          <Typography
            variant="h6"
            color="inherit"
            className={classes.grow}
            onClick={() => Backend.ping()}
          >
            {this.state.headline}
          </Typography>
          <Typography variant="h6" color="inherit" className={classes.grow} />
          {currentUser ? (
            <React.Fragment>
              {/* hidden log file download button */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  height: "100%",
                  width: 20,
                }}
                onClick={() => {
                  Backend.getAbout((result) => {
                    if (result.logFiles && result.logFiles.length > 0) {
                      let newestLogFile = result.logFiles[0];

                      const a = document.createElement("a");
                      let url =
                        "api/about/logfile?name=" +
                        window.decodeURIComponent(newestLogFile);
                      a.href = url;
                      a.download = url.split("/").pop();
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }
                  });
                }}
              ></div>
              <Button
                style={{
                  position: "relative",
                  minWidth: 130,
                  background:
                    this.state.headline === "Home" ? "rgba(0,0,0,0.2)" : "none",
                }}
                onClick={() => {
                  this.props.history.push("/");
                  this.setState({ headline: "Home" });
                }}
                className={classes.headerButton}
                color="inherit"
              >
                <WorkIcon
                  style={{
                    position: "absolute",
                    top: 17,
                    left: 22,
                    filter: "drop-shadow( -2px 2px 0px rgba(6,115,193,1))",
                    zIndex: 1,
                  }}
                />
                <WorkIcon
                  style={{
                    marginRight: 5,
                    position: "relative",
                    right: 3,
                    top: 2,
                  }}
                />
                <strong>Projects</strong>
              </Button>

              <Button
                disabled={!this.state.canAccessAITab}
                style={{
                  position: "relative",
                  background:
                    this.state.headline === "AI Viewer"
                      ? "rgba(0,0,0,0.2)"
                      : "none",
                }}
                onClick={() => {
                  this.props.history.push("/ai_view");
                  this.setState({ headline: "AI Viewer" });
                }}
                className={classes.headerButton}
                color="inherit"
              >
                <BarChartIcon
                  style={{
                    marginRight: 5,
                    position: "relative",
                    right: 3,
                    top: 2,
                  }}
                />
                <strong>AI</strong>
              </Button>

              <Button
                disabled={!this.state.canAccessCases}
                style={{
                  background:
                    this.state.headline === "Cases"
                      ? "rgba(0,0,0,0.2)"
                      : "none",
                }}
                onClick={() => this.props.history.push("/cases")}
                className={classes.headerButton}
                color="inherit"
              >
                <PermMediaIcon style={{ marginRight: 5 }} />
                <strong>Cases</strong>
              </Button>

              <Tooltip
                title={this.state.canAccessFileView ? "" : "No Permission!"}
              >
                <span>
                  <Button
                    style={{
                      background:
                        this.state.headline === "Files"
                          ? "rgba(0,0,0,0.2)"
                          : "none",
                    }}
                    disabled={!this.state.canAccessFileView}
                    onClick={() => this.props.history.push("/file_view")}
                    className={classes.headerButton}
                    color="inherit"
                  >
                    <FolderCopyIcon style={{ marginRight: 5 }} />
                    <strong>Files</strong>
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title={expirationMessage}>
                <span>
                  <Button
                    disabled={
                      scannerState !== "VALID" || !this.state.canAccessScan
                    }
                    style={{
                      position: "relative",
                      background:
                        this.state.headline === "Scan Viewer"
                          ? "rgba(0,0,0,0.2)"
                          : "none",
                    }}
                    onClick={() => {
                      this.props.history.push("/scan_view");
                    }}
                    className={classes.headerButton}
                    color="inherit"
                  >
                    <ScannerIcon
                      style={{
                        marginRight: 5,
                      }}
                      className={
                        scannerState !== "VALID" || expirationMessage === ""
                          ? ""
                          : classes.warningColor
                      }
                    />
                    <strong
                      className={
                        scannerState !== "VALID" || expirationMessage === ""
                          ? ""
                          : classes.warningColor
                      }
                    >
                      Scan
                    </strong>
                  </Button>
                </span>
              </Tooltip>

              {this.state.extraButtonState !== "" && (
                <React.Fragment>
                  {this.state.extraButtonState === "results" ? (
                    <Button
                      style={{
                        background:
                          this.state.headline &&
                          this.state.headline.includes("Results:")
                            ? "rgba(0,0,0,0.2)"
                            : "none",
                      }}
                      disabled={this.state.resultsPath === ""}
                      onClick={() =>
                        this.props.history.push(this.state.resultsPath)
                      }
                      className={classes.headerButton}
                      color="inherit"
                    >
                      <SvgIcon style={{ marginRight: 5 }}>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="table"
                          className="svg-inline--fa fa-table fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                          ></path>
                        </svg>
                      </SvgIcon>
                      <strong>Results</strong>
                    </Button>
                  ) : (
                    <Button
                      disabled={this.state.projectPath === ""}
                      onClick={() => {
                        this.props.history.push(this.state.projectPath);
                      }}
                      className={classes.headerButton}
                      color="inherit"
                    >
                      <WorkIcon style={{ marginRight: 5 }} />
                      <strong>Project</strong>
                    </Button>
                  )}
                </React.Fragment>
              )}

              {showDevButtons && (
                <React.Fragment>
                  <Button
                    style={{
                      background:
                        this.state.headline === "Prediction"
                          ? "rgba(0,0,0,0.2)"
                          : "none",
                    }}
                    onClick={() => this.props.history.push("/prediction")}
                    className={classes.headerButton}
                    color="inherit"
                  >
                    <WorkIcon style={{ marginRight: 5 }} />
                    <strong>Prediction</strong>
                  </Button>
                  <Button
                    style={{
                      background:
                        this.state.headline === "Classroom"
                          ? "rgba(0,0,0,0.2)"
                          : "none",
                    }}
                    onClick={() => {
                      if (window.location.href.includes("/view/")) {
                        window.toggleClassRoomChat();
                      } else {
                        window.openWarningDialog("Please open a Project first");
                      }
                    }}
                    className={classes.headerButton}
                    color="inherit"
                  >
                    <ScreenShareIcon style={{ marginRight: 5 }} />
                    <strong>Classroom</strong>
                  </Button>
                </React.Fragment>
              )}

              <Button
                ref={(node) => {
                  this.anchorEl = node;
                }}
                className={classes.headerButton}
                aria-owns={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={() => this.handleMenu()}
                color="inherit"
              >
                <Person style={{ marginRight: 5 }} />
                <strong>{currentUser.fullName}</strong>
                <ArrowDropDown style={{ marginLeft: 5 }} />
              </Button>
              <Popper
                open={open}
                anchorEl={this.anchorEl}
                transition
                disablePortal
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    className={classes.menu}
                  >
                    <Paper square>
                      <ClickAwayListener
                        onClickAway={(e) => this.handleClose(e)}
                      >
                        <MenuList style={{ padding: 0 }}>
                          {canAccessAdminPage && (
                            <MenuItem
                              onClick={() => this.props.history.push("/admin")}
                            >
                              <CoPresent className={classes.menuIcon} />
                              Admin
                            </MenuItem>
                          )}
                          {canAccessLicensingPage && (
                            <MenuItem
                              onClick={() => {
                                this.props.history.push("/licensing");
                              }}
                            >
                              <Build className={classes.menuIcon} />
                              Licensing Page
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() =>
                              this.props.history.push("/userhistory")
                            }
                          >
                            <History className={classes.menuIcon} />
                            User Access History
                          </MenuItem>
                          <MenuItem
                            onClick={() => this.props.history.push("/about")}
                          >
                            <Info className={classes.menuIcon} />
                            About / Help
                          </MenuItem>
                          <MenuItem onClick={this.logout}>
                            <ExitToApp className={classes.menuIcon} />
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </React.Fragment>
          ) : (
            <Button
              color="inherit"
              onClick={() => this.props.history.push("/login")}
            >
              Sign In
            </Button>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

NavigationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(withStyles(styles)(NavigationBar));
